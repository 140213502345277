import { App as Drawer } from 'map-create-drawer'
import { useState } from 'react'
import { HomeHero as Hero } from 'ui-components'

function HomeHero() {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Hero onClick={handleClick} />
      <Drawer
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

export { HomeHero }
