import { QueryKey, UseQueryResult, useQuery } from '@tanstack/react-query'
import {
  GetFeaturedMapsQuery,
  QueryGetFeaturedMapsArgs
} from '~website/types/__generated/gql/graphql'
import { graphierQL } from 'utils'

const queryGetFeaturedMaps = /* GraphQL */ `
  query GetFeaturedMaps {
    getFeaturedMaps {
      total
      items {
        id
        dataId
        name
        description
        likes
        views
        liked
        createdAt
        updatedAt
        workspace {
          id
          name
          logo
          createdAt
          updatedAt
        }
      }
    }
  }
`

type GetFeaturedMapsArgs = QueryGetFeaturedMapsArgs
type GetFeaturedMapsResults = GetFeaturedMapsQuery['getFeaturedMaps']

const getFeaturedMapsQueryKey = (variables: GetFeaturedMapsArgs): QueryKey => {
  return ['useGetFeaturedMaps', variables]
}

const useGetFeaturedMaps = (): UseQueryResult<GetFeaturedMapsResults> => {
  return useQuery({
    queryKey: getFeaturedMapsQueryKey({}),
    queryFn: () =>
      graphierQL<GetFeaturedMapsQuery>({
        query: queryGetFeaturedMaps
      }).then((res) => res.getFeaturedMaps)
  })
}

export { getFeaturedMapsQueryKey, useGetFeaturedMaps }
export type { GetFeaturedMapsArgs, GetFeaturedMapsResults }
