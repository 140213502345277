import { ExampleMaps as Component } from 'ui-components'
import { useGetFeaturedMaps } from '~website/queries/useGetFeaturedMaps'

function ExampleMaps() {
  const { data, isLoading } = useGetFeaturedMaps()

  return (
    <Component
      maps={data?.items || []}
      isLoading={isLoading}
    />
  )
}

export { ExampleMaps }
