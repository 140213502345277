import { slug } from 'utils'

export function homePageView() {
  gtag('event', 'page_view', {
    page_location: '/'
  })
}

export function explorePageView() {
  gtag('event', 'page_view', {
    page_location: '/explore'
  })
}

export function mapPageView(mapId: string, mapName: string) {
  gtag('event', 'page_view', {
    page_location: `/map/${mapId}/${slug(mapName)}`
  })
}

export function searchPageView() {
  gtag('event', 'page_view', {
    page_location: '/search'
  })
}

export function joinPageView(
  workspaceId: string,
  workspaceName: string,
  inviteId: string
) {
  gtag('event', 'page_view', {
    page_location: [
      `/workspace`,
      `/${workspaceId}`,
      `/${workspaceName}`,
      `/admin`,
      `/join?inviteId=${inviteId}`
    ].join('')
  })
}

export function sharedInvitePageView(
  workspaceId: string,
  workspaceName: string,
  inviteId: string
) {
  gtag('event', 'page_view', {
    page_location: [
      `/workspace`,
      `/${workspaceId}`,
      `/${workspaceName}`,
      `/admin`,
      `/shared-invite?inviteId=${inviteId}`
    ].join('')
  })
}
