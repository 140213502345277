import { Header } from 'header'
import { Privacy as Component, Seo } from 'ui-components'
import { AppProvider } from '../AppProvider'

const Privacy = () => {
  return (
    <AppProvider>
      <Seo
        title='Privacy Policy'
        description='Mapstack is a free-to-use online mapping tool. Use it to create interactive maps, visualisations and info graphics.'
      />
      <Component header={() => <Header anonymousOnly={true} />} />
    </AppProvider>
  )
}

export { Privacy }
