import { Header } from 'header'
import { Home as Layout, Seo } from 'ui-components'

import { AppProvider } from '../AppProvider'
import { ExampleMaps } from './ExampleMaps'
import { HomeHero } from './HomeHero'

const providers = (Component) => {
  const WrappedComponent = (props) => {
    return (
      <AppProvider>
        <Seo
          title='Mapstack: Effortlessly Transform Spreadsheets into Amazing Maps'
          description='Mapstack makes it simple to turn your spreadsheets into interactive maps, revealing hidden patterns for better decision making. Start mapping today and uncover insights from your data effortlessly.'
          additionalMetaTags={[
            {
              name: 'keywords',
              content:
                'data visualization, interactive maps, spreadsheet maps, Mapstack, geographic insights, data mapping, visual data analysis, easy map creation, map insights'
            }
          ]}
        />
        <Component {...props} />
      </AppProvider>
    )
  }

  return WrappedComponent
}

const Home = providers(() => {
  return (
    <Layout
      slots={{
        Header,
        HomeHero,
        ExampleMaps
      }}
      slotProps={{
        Header: {
          anonymousOnly: true
        }
      }}
    />
  )
})

export { Home }
